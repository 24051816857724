<template>
	<van-dialog
		v-model="visible"
		:title="getTitle"
		:show-confirm-button="false"
    class="list-type-dialog"
	>
		<div class="list-type-dialog-close">
      <van-icon name="cross" @click="clickCancel"/>
    </div>
		<div class="list-type-dialog-main">
			<van-radio-group v-model="value">
				<list-type-dialog-item
					v-for="item in list"
					:key="item.id"
					:value="value"
					:item="item"
				></list-type-dialog-item>
			</van-radio-group>
			<van-empty
				v-if="!loading && !list.length"
				:image="`${
					$config[$store.getters['user/getAppid']].fileBaseUrl
				}/empty.png`"
				description="暂无数据"
			/>
		</div>
		<div class="list-type-dialog-action">
			<van-button type="primary" block :disabled="!value" @click="clickConfirm">确 认</van-button>
		</div>
	</van-dialog>
</template>

<script>
import ListTypeDialogItem from "./list-type-dialog-item.vue";
export default {
	data: () => ({
		visible: false,
		list: [],
		loading: true,
		value: "",
		params: {
			surveyId: "",
			type: "",
      budgetSettingId: "",
      budgetId: "",
			taskId: "",
		},
	}),
	components: {
		ListTypeDialogItem,
	},
	computed: {
		getTitle() {
			const title = this.$route.query.type == 2 ? "临床" : "调研";
			return `请选择${title}类型`;
			// return `请选择表单`
		},
	},
	methods: {
		init({ id, type, surveyTask }) {
			const { taskId = -1 } = surveyTask;
			this.$set(this.params, "surveyId", id);
			this.$set(this.params, "type", type);
			this.$set(this.params, "budgetId", surveyTask.budgetId);
			this.$set(this.params, "budgetSettingId", surveyTask.budgetSettingId);
			this.$set(this.params, "taskId", surveyTask.id || -1);
			this.list = [];
			this.getDataPage();
		},
		async getDataPage() {
			try {
				this.loading = true;
				const res = await this.$api.surveys.formTypePage(this.params);
				if (!res.code) {
					this.list = res.data;
					this.visible = true;
				}
				this.loading = false;
			} catch (err) {
				this.loading = false;
			}
		},
		clickCancel() {
			this.visible = false;
			this.value = "";
		},
		clickConfirm() {
			if (!this.value) {
				this.$toast("请选择表单");
				return;
			}
			this.$emit("confirm", {
				...this.params,
				selectId: this.value,
			});
		},
	},
};
</script>
