<template>
  <div class="i-surveys-list base-height">
    <div class="i-surveys-list-main">
      <div class="i-surveys-list-main-box">
        <div class="box-header van-hairline--bottom">
          <div class="title">i{{ $status.iSurveys.type[$route.query.type] }}任务</div>
        </div>
        <div class="box-list">
          <van-list v-if="loading || total" :value="loading" :finished="finished" @load="loadMore">
            <list-item v-for="item in list" :key="item.id" :item="item" @click="clickItem" @action="clickAction"></list-item>
          </van-list>
          <van-empty v-else :image="`${$config[$store.getters['user/getAppid']].fileBaseUrl}/empty.png`" description="暂无数据" />
        </div>
      </div>
    </div>

    <!-- 选择类型弹窗 -->
    <list-type-dialog ref="refListTypeDialog" @confirm="clickConfirmDialog" />
  </div>
</template>
<script>
import ListItem from './components/list-item.vue'
import ListTypeDialog from './components/list-type-dialog.vue'
import listMixins from '@/mixins/list'
import { mapMutations, mapState } from 'vuex'
export default {
  mixins: [listMixins],
  components: {
    ListItem,
    ListTypeDialog
  },
  data: () => ({
    selectParams: {
      type: 1,
      activityId: '',
      // typeIn: [1,2],
      orderField: 'publish_time'
    }
  }),
  computed: {
    ...mapState('surveys', ['surveysTitle']),
    apiSelect() {
      return this.$api.surveys.page
    }
  },
  created() {
    // 设置表单类型	类型:1-调研 2-临床
    this.$route.query.type && this.$set(this.selectParams, 'type', this.$route.query.type)
    this.SET_PAGE_TITLE(this.surveysTitle)
    this.$set(this.selectParams, 'activityId', this.$route.query.activityId || '')
  },
  methods: {
    ...mapMutations('surveys', ['SET_PAGE_TITLE']),
    clickItem({ value }) {
      this.$refs.refListTypeDialog.init(value)
    },
    clickAction({ value }) {
      this.$router.push({
        path: '/i-surveys/result-list',
        query: {
          type: value.type,
          surveyId: value.id
        }
      })
    },
    // 点击确认 -- 弹窗
    clickConfirmDialog(val) {
      this.$router.push({
        path: `/i-surveys/form/${val.selectId}`,
        query: val
      })
    }
  }
}
</script>
<style lang="less" scoped>
.base-height {
  min-height: 100vh;
}
</style>