<template>
    <div class="list-type-dialog-item" :class="getClass" @click="handleClick">
        <van-radio :name="item.id" :disabled="item.doctorComplete">
            <span class="list-type-dialog-item--text">{{ item.title }}</span>
        </van-radio>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        value: {
            type: [Number, String],
            default: ''
        }
    },
    computed: {
        getClass () {
            const _class = []
            if (this.value === this.item.id) {
                _class.push('active')
            }
            return _class
        }
    },
    methods: {
      handleClick () {
        if (this.item.doctorComplete) {
          this.$toast(this.item.tips);
        }
      }
    }
}
</script>
