export default {
    data: () => ({
        loading: true,
        finished: false,
        list: [],
        total: 0,
        params: {
            page: 1,
            limit: 10,
            order: 'desc',
            orderField: 'create_time'
        }
    }),
    mounted () {
        if (this.unMounted) return
        this.getDataPage();
    },
    methods: {
        async getDataPage () {
            try {
                this.loading = true
                const res = await this.apiSelect({
                    ...this.params,
                    ...(this.selectParams ?? {})
                })

                if (!res.code) {
                    this.getDataPageDataFormat(res.data)
                }
                this.loading = false
            } catch (err) {
                this.loading = false
            } 
        },

        getDataPageDataFormat ({ list, total }) {
            let _list = [];
         
            if (typeof this.selectInterceptor === 'function') {
                list = this.selectInterceptor(list);
            }

            if (this.params.page === 1) {
                _list = list
            } else {
                _list = this.list.concat(list)
            }

            this.list = _list
            this.total = total
            this.finished = _list.length >= total
        },

        // 加载更多
        loadMore () {
          console.log(!this.finished, !this.loading)
            if (!this.finished && !this.loading) {
                this.$set(this.params, 'page', this.params.page + 1)
                this.getDataPage()
            }
        },

        handleSearch () {
            this.$set(this.params, 'page', 1)
            this.getDataPage();
        }
    }
}