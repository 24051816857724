<template>
    <div class="i-surveys-list-item van-hairline--bottom">
      <div class="item-main">
        <div class="title">
          <div class="status" :class="statusClassName">{{ runStatus }}</div>
          <div class="title--inner van-ellipsis">{{ item.title }}</div>
        </div>
        <div class="desc-cell">答题数量 {{ examCount }}</div>
      </div>
      <div class="item-footer">
        <van-button type="primary" @click="handleClick">新建</van-button>
        <van-button type="default" @click="handleAction">历史提交<span class="red-drop" v-if="item.redDot"></span></van-button>
      </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        statusMap () {
            return this.$status.iSurveys
        },
        runStatus () {
            return this.$status.activity.runStatus[this.item.activity?.activityStatus]
        },
        statusClassName () {
          switch (this.runStatus) {
            case '未开始': return 'before'
            case '进行中': return 'playing'
            case '已结束': return 'after'
            default: return ''
          }
        },
        activityName () {
            if (this.item.activity.name.length > 5) {
                return this.item.activity.name.substring(0, 5) + '...'
            }
            return this.item.activity.name
        },
        createDate () {
            return this.item.publishTime.split(' ')[0]
        },
        examCount () {
            if (this.item.surveyTask) {
                const { completeNumber = 0, total = 0 } = this.item.surveyTask
                if (total == -1) return '不限制'
                return `${completeNumber}/${total}`
            }
            return '0/0'
        }
    },
    methods: {
        handleClick () {
            if (this.item.activity?.activityStatus == 2) {
                const { completeNumber = 0, total = 0 } = this.item.surveyTask
                if (total > -1 && completeNumber >= total) {
                    this.$toast(`暂无可参与次数，请查看进度`);
                } else {
                  this.checkActivityStatus()
                    // this.$emit('click', {
                    //   value: this.item
                    // }) 
                }
                
            } else {
                this.$toast(`活动${this.runStatus}`);
            }
        },
        // 判断活动课题是否可用
        checkActivityStatus () {
          if (this.$route.query.activityId) {
            this.$emit('click', { value: this.item })
            return
          }
          this.$api.activity.checkActivityStatus({
            type: 2,
            doctorTaskId: this.item.surveyTask.id
          }).then(res => {
            if (!res.code) {
              if (res.data === 1) {
                this.$emit('click', {
                  value: this.item
                }) 
              } else {
                this.$toast('活动未激活')
              }
            }
          })
        },
        handleAction () {
            this.$emit('action', {
                value: this.item
            })
        }
    }
}
</script>
